import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { ReactComponent as Forklift } from "../../Assests/Icons/forklift.svg";
import ScrollToTop from "react-scroll-to-top";

// Import Utils
import { scrollToSection } from "../../Utility/Utils";

// Importing the css files
import "./Cart.scss"; //styles exclusive to this page only

// Importing components
import Navigation from "../../Components/Navigation/Navigation";
import Checkout from "../../Components/CheckoutProcess/Checkout";
import CartList from "../../Components/Cart/CartList/CartList";
import CartTotal from "../../Components/Cart/CartTotal/CartTotal";
import Footer from "../../Components/Footer/Footer";
import FAQ from "../../Components/FAQ/FAQ";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
    selectCart,
    setActiveAddress,
    setDeliveryMethod,
    setPaymentMethod,
} from "../../Redux/Features/Cart/cartSlice";
import GenericEmailButton from "../../Components/GenericEmailButton/GenericEmailButton";

// Importing SEO component
import SEO from "../../Components/SEO/SEO";
import Thumbnail from "../../Assests/minestar-thumbnail.webp";
import { useLocation } from "react-router-dom";

const Cart = () => {
    //get URL for SEO
    const url = useLocation();
    const path = url.pathname;

    const currentSection = useRef(null);
    const [active, setActive] = useState("");
    const [deliveryOption, setDeliveryOption] = useState("unset");
    const [isOrderProcessComplete, setIsOrderProcessComplete] = useState(false);
    const [isSummaryDisplayed, setIsSummaryDisplayed] = useState(false);

    const cart = useSelector(selectCart);
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        setActive("cart");
    }, []);

    useEffect(() => {
        if (deliveryOption !== "unset") {
            dispatch(setDeliveryMethod(deliveryOption));
        }
    }, [deliveryOption, dispatch]);

    // 👇️ scroll to top on page load
    useEffect(() => {
        if (active === "cart") {
            scrollToSection(currentSection);
        }
    }, [active]);

    return (
        <div className="container">
            <SEO 
                title="Cart | Minestar South Africa"
                description="From mining to construction vehicles, we supply all your earthmoving tyre needs. 
                                Shop our OTR tyres for sale now with Minestar South Africa!"
                type="website"
                image={Thumbnail}
                canonUrl={path}
            />
            <div className="cartHeader">
                <header className="main-header" role="banner">
                    {/* Beginning: The main navigation component */}
                    <Navigation key={0} page="CartPage" />
                    {/* End: The main navigation component */}

                    <section className="homepage-hero">
                        <div>
                            <div className="common-header ml-3 ml-md-5 pl-md-4">
                                <h1>
                                    {active === "cart" ? "Cart" : "Checkout"}
                                </h1>
                            </div>
                        </div>
                    </section>
                </header>
            </div>
            {active === "cart" ? (
                <div
                    ref={currentSection}
                    className="cart-content row mx-0 justify-content-evenly"
                >
                    <div className="cart-list col-12 col-md-7">
                        <CartList key={1} />
                    </div>
                    {cart.count > 0 && (
                        <div className="cart-total col-12 col-md-5">
                            <CartTotal key={2} setActive={setActive} />
                        </div>
                    )}
                </div>
            ) : (
                active === "checkout" && (
                    <>
                        <div className="go-back-cart mt-4 mb-2 mb-md-0 d-flex justify-content-end">
                            <button
                                className="go-back-cart-btn mx-2 mx-md-5"
                                onClick={() => {
                                    setActive("cart");
                                    setIsOrderProcessComplete(false);
                                    setDeliveryOption("unset");

                                    // Reset cartSlice
                                    dispatch(setActiveAddress(null));
                                    dispatch(setPaymentMethod(null));
                                    dispatch(setDeliveryMethod(null));
                                }}
                            >
                                <Forklift className="menu-icon mr-1" />
                                Back to Cart
                            </button>
                        </div>
                        <div className="checkout-content row mx-0 px-0 justify-content-center">
                            <div className="px-0 px-md-2 col-12">
                                <Checkout
                                    key={1}
                                    setActive={setActive}
                                    deliveryOption={deliveryOption}
                                    setDeliveryOption={setDeliveryOption}
                                    isOrderProcessCompleteState={{
                                        isOrderProcessComplete,
                                        setIsOrderProcessComplete,
                                    }}
                                    isSummaryDisplayedState={{
                                        isSummaryDisplayed,
                                        setIsSummaryDisplayed,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )
            )}

            <FAQ />

            <div className="py-5 px-2">
                {/*contact/get in touch/need help links*/}
                <h2 className="contact-us-header">
                    Having trouble with the cart ?{" "}
                    <span className="d-block d-md-inline-block">
                        Contact us:
                    </span>
                </h2>
                <div className="contact-us-options">
                    {/* <button to="/">CHAT WITH US</button>
                    <br />
                    <p>or</p> */}
                    <GenericEmailButton />
                </div>
            </div>

            {/* scroll to top */}
            <ScrollToTop smooth />
            {/* scroll to top */}

            {/* Beginning: footer */}
            <Footer />
            {/* End: footer */}
        </div>
    );
};

export default Cart;
