/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Importing the css files
import "./RegisterPage.scss";

// Importing components
import RegisterForm from "../Forms/Register/RegisterForm";
import RegisterAddressForm from "../Forms/AddressForm/RegisterAddressForm";

// import images
import Logo from "../../Assests/Icons/main.png";

// Importing SEO component
import SEO from "../../Components/SEO/SEO";
import Thumbnail from "../../Assests/minestar-thumbnail.webp";

const RegisterPage = () => {
    //get URL for SEO
    const url = useLocation();
    const path = url.pathname;

    const [active, setActive] = useState("Register");
    const [userType, setUserType] = useState("Buyer");
    const [userDetails, setUserDetails] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phonenumber: "",
        password: "",
        password2: "",
    });

    const [userAddress, setUserAddress] = useState({
        id: 0,
        strCountry: "",
        strAddressLineOne: "",
        strAddressLineTwo: "",
        strProvince: "",
        strCity: "",
        strPostalCode: "",
        bAcceptTsAndCs: false,
    });

    const navigate = useNavigate();
    console.log("user: ", userDetails);
    console.log("address: ", userAddress);

    return (
        <div className="container">
            <SEO 
                title="Register | Minestar South Africa"
                description="From mining to construction vehicles, we supply all your earthmoving tyre needs. 
                                Shop our OTR tyres for sale now with Minestar South Africa!"
                type="website"
                image={Thumbnail}
                canonUrl={path}
            />
            <div className="registerContainer">
                <div
                    className="login-logo-container col-12"
                    onClick={() => navigate("/")}
                >
                    <img
                        src={Logo}
                        width={500}
                        alt="Logo"
                        className=""
                        id="login-logo"
                    />
                </div>
                <div className="col-12 col-lg-8 LoginCover"></div>
                <div className="row col-12 col-lg-4 loginForm d-flex justify-content-center align-items-center my-3">
                    <div className="col-12 form-content-right d-flex flex-column align-items-center justify-content-center">
                        {/* <div className="d-flex flex-column align-items-center"> */}
                        <div className="col-12 row mx-0 align-items-center justify-content-center">
                            {active === "Register" && (
                                <RegisterForm
                                    setActive={setActive}
                                    userDetailsState={{
                                        userDetails,
                                        setUserDetails,
                                    }}
                                    type={{ userType, setUserType }}
                                />
                            )}
                            {active === "RegisterAddress" && (
                                <RegisterAddressForm
                                    setActive={setActive}
                                    userDetails={userDetails}
                                    userAddressState={{
                                        userAddress,
                                        setUserAddress,
                                    }}
                                    userType={userType}
                                />
                            )}
                        </div>
                        <h3 className="go-back-btn mt-2 mt-md-4 mb-3">
                            <a href="#" onClick={() => navigate("/")}>
                                Go Back To Site
                            </a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
