import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, description, type, image, canonUrl}) {
    const domain = window.location.origin;
return (
    <Helmet>
    { /* Standard metadata tags */ }
    <link rel="canonical" href={domain + canonUrl} />
    <title>{title}</title>
    <meta name='description' content={description} />
    { /* End standard metadata tags */ }
    { /* Facebook tags */ }
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={domain + image} /> {/* The image link must be the full url to the image */}
    { /* End Facebook tags */ }
    { /* Twitter tags */ }
    <meta name="twitter:card" content={type} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={domain + image} /> {/* The image link must be the full url to the image */}
    { /* End Twitter tags */ }
    </Helmet>
)
}