import React, { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";

//  Importing the css files
import "./ResellerPage.scss";

// Importing components
import Navigation from "../../Components/Navigation/Navigation";
import Footer from "../../Components/Footer/Footer";
import GenericEmailButton from "../../Components/GenericEmailButton/GenericEmailButton";
import ResellerFAQ from "../ResellerPage/ResellerFAQ";
import { useNavigate, useLocation } from "react-router-dom";

// Importing SEO component
import SEO from "../../Components/SEO/SEO";
import Thumbnail from "../../Assests/minestar-thumbnail.webp";

const ResellerUsPage = ({ navigation }) => {
    //get URL for SEO
    const url = useLocation();
    const path = url.pathname;

    const navigate = useNavigate();
    // 👇️ scroll to top on page load
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <div className="container">
            <SEO 
                title="Become A Tyre Reseller | Minestar South Africa"
                description="Apply now with Minestar South Africa now to become a reseller 
                                and manage the sale of your OTR tyres with ease!"
                type="website"
                image={Thumbnail}
                canonUrl={path}
            />
            <div className="reseller-page-header">
                <header className="main-header" role="banner">
                    {/* Beginning: The main navigation component */}
                    <Navigation page="ResellerPage" />
                    {/* End: The main navigation component */}

                    {/* reseller hero section */}
                    <section className="homepage-hero">
                        <div className="row mx-0">
                            <div className="common-header ml-3 ml-md-5 pl-md-4">
                                <h1>Become a reseller</h1>
                            </div>
                        </div>
                    </section>
                    {/* end of reseller hero section */}
                </header>
            </div>
            {/*reseller section*/}
            <div className="row mx-0">
                <div className="reseller-pre-container d-flex justify-content-center align-items-center col-lg-6 col-12">
                    <div id="reseller-pre-content">
                        <h2 className="mb-4">Why use Minestar’s reseller platform?</h2>
                        <p className="reseller-text">
                            Minestar is a reliable and efficient platform that
                            makes your job easier. One of the standout features
                            of our platform is its ease of use. It is simple for
                            resellers to create an account, showcase and sell
                            their products. Customers can then add items to
                            their cart and move closer to securing the items
                            they need. By using our reseller platform, you can
                            take advantage of the provided features to
                            streamline your business operations and focus on
                            growing your business.
                        </p>
                    </div>
                </div>
                <div className="reseller-page-content col-lg-6 col-12">
                    <div className="d-block d-md-flex mt-3 mt-md-0 row mx-0">
                        <div className="px-3 p-md-3 p-lg-3 col-12 col-lg-10">
                            {/* <div> */}
                            <h2 className="resellerHeaders m-0 title-xl">Features</h2>
                            <span className="reseller">
                                <ul className="list">
                                    <li>
                                        <h3>Easy Account Creation and Login</h3>
                                        <ul className="indented-list">
                                            <li>
                                                Simple and straightforward account
                                                creation process.
                                            </li>
                                            <li>
                                                Easy login process for quick access
                                                to your account.
                                            </li>
                                        </ul>
                                    </li>
                                    
                                    <li>
                                        <h3>Extensive Product Selection</h3>
                                        <ul className="indented-list">
                                            <li>
                                                Customers can easily search and
                                                filter products to find what they
                                                need.
                                            </li>
                                        </ul>
                                    </li>
                                    
                                    <li>
                                    <h3>Automatic Order Updates</h3>
                                        <ul className="indented-list">
                                            <li>
                                                Real-time order updates sent via
                                                email to keep you informed of when
                                                one or more of your products have
                                                been included in an order.
                                            </li>
                                        </ul>
                                    </li>
                                    
                                    <li>
                                        <h3>Online Order History</h3>
                                        <ul className="indented-list">
                                            <li>
                                                Access to your complete order
                                                history online.
                                            </li>
                                            <li>View past transactions.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </span>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-block d-lg-flex bg-navy py-4 align-items-center justify-content-center px-5">
                <div className="col-lg-5 pb-4 py-lg-0">
                    <p className="contact-banner-text text-center text-lg-left">
                        Are you a supplier looking to sell on our website?
                    </p>
                </div>
                <div className="col-lg-5 text-center">
                    <button
                        onClick={() => {
                            navigate("/register", { state: true });
                        }}
                        className="contact-banner-button px-4 py-2"
                        type="submit"
                    >
                        Apply Now
                    </button>
                </div>
            </div>

            <ResellerFAQ />

            {/*contact/get in touch/need help links*/}
            <div className="contact-us py-3">
                <p className="contact-us-header title-lg text-bold">Have any questions?</p>
                <div className="contact-us-options">
                    <GenericEmailButton />
                </div>
            </div>

            {/* scroll to top */}
            <ScrollToTop smooth />
            {/* scroll to top */}

            {/* Beginning: footer */}
            <Footer />
            {/* End: footer */}
        </div>
    );
};

export default ResellerUsPage;
