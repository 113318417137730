import React, { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";

// Importing the css files
import "./Contact.scss"; //styles exclusive to this page only

// Importing components
import Navigation from "../../Components/Navigation/Navigation";
import ContactForm from "../../Components/Forms/ContactUsForm/Contact";
import Footer from "../../Components/Footer/Footer";

// Importing SEO component
import SEO from "../../Components/SEO/SEO";
import Thumbnail from "../../Assests/minestar-thumbnail.webp";
import { useLocation } from "react-router-dom";

const ContactUsPage = () => {
    //get URL for SEO
    const url = useLocation();
    const path = url.pathname;

    // 👇️ scroll to top on page load
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <div className="container">
            <SEO 
                title="Contact Us | Minestar South Africa"
                description="Get in touch with Minestar now and let us help you make the right choice for your OTR tyre needs!"
                type="website"
                image={Thumbnail}
                canonUrl={path}
            />
            <div className="contact-page-header">
                <header className="main-header" role="banner">
                    {/* Beginning: The main navigation component */}
                    <Navigation page="ContactUsPage" />
                    {/* End: The main navigation component */}

                    {/* contact us hero section  */}
                    <section className="homepage-hero">
                        <div className="row mx-0">
                            <div className="common-header ml-3 ml-md-5 pl-md-4">
                                <h1>We'd love to keep in contact with you</h1>
                            </div>
                        </div>
                    </section>
                    {/* end of contact us hero section */}
                </header>
            </div>
            {/* scroll to top */}
            <ScrollToTop smooth />
            {/* scroll to top */}

            {/* Beginning: Contact Form */}
            <ContactForm />
            {/* End: Contact Form */}

            {/* Beginning: footer */}
            <Footer />
            {/* End: footer */}
        </div>
    );
};

export default ContactUsPage;
