import React, { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";

//  Importing the css files
import "./AboutUs.scss";

// Importing components
import Navigation from "../../Components/Navigation/Navigation";
import Footer from "../../Components/Footer/Footer";
import GenericEmailButton from "../../Components/GenericEmailButton/GenericEmailButton";

// Importing SEO component
import SEO from "../../Components/SEO/SEO";
import Thumbnail from "../../Assests/minestar-thumbnail.webp";
import { useLocation } from "react-router-dom";

const AboutUsPage = () => {
    //get URL for SEO
    const url = useLocation();
    const path = url.pathname;
    
    // 👇️ scroll to top on page load
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <div className="container">
            <SEO 
                title="About Us | Minestar South Africa"
                description="We specialise in supplying leading OTR tyres for your ease and convenience. 
                                Shop our OTR mining tyre solutions now with Minestar South Africa!"
                type="website"
                image={Thumbnail}
                canonUrl={path}
            />
            <div className="about-page-header">
                <header className="main-header" role="banner">
                    {/* Beginning: The main navigation component */}
                    <Navigation page="AboutUsPage" />
                    {/* End: The main navigation component */}

                    {/* about hero section */}
                    <section className="homepage-hero">
                        <div className="row mx-0">
                            <div className="common-header ml-3 ml-md-5 pl-md-4">
                                <h1>Get to know us more</h1>
                            </div>
                        </div>
                    </section>
                    {/* end of about hero section */}
                </header>
            </div>
            {/*about us section*/}
            <div className="row mx-0">
                <div className="about-us-pre-container d-flex justify-content-center align-items-center col-12 col-lg-6">
                    <div id="about-us-pre-content">
                        <p>
                            Welcome to Minestar, South Africa’s premier
                            off-the-road tyre sales company with 25 years of
                            industry experience. Our vision is to become the
                            most dynamic OTR supplier and service company in the
                            country. We’re committed to achieving this goal by
                            providing our customers with top-quality products
                            and unparalleled service.
                            <br />
                            <br />
                            We specialise in supplying all leading OTR tyre
                            brands to the industry. Our team can source the
                            right tyres and wheels for your specific
                            requirements, no matter the size or complexity of
                            the job.
                            <br />
                            <br />
                            Customer satisfaction is the heart of our business.
                            We strive to meet and exceed our customers'
                            expectations by providing them with personalised
                            service, expert advice, and the highest quality
                            products available. Most of our client base has been
                            with us for almost 20 years.
                            <br />
                            <br />
                            Thank you for considering Minestar for your
                            off-the-road tyre needs. We look forward to serving
                            you and building a long-lasting relationship that is
                            based on trust, reliability, and exceptional
                            service.
                        </p>
                    </div>
                </div>
                <div className="page-content col-12 col-lg-6 d-flex justify-content-center align-items-center">
                    <div className="row mx-0 justify-content-around mt-3 mt-md-0">
                        <div className="vision-container px-0 p-md-3 p-lg-3 col-11 col-md-7 col-lg-10 mx-1">
                            <div>
                                <h2 className="aboutUsHeaders title-xl">
                                    Our Vision
                                </h2>
                                <span className="aboutUs">
                                    Our vision is to become the most dynamic OTR
                                    supplier and service company in the country.
                                    We’re committed to achieving this goal by
                                    providing our customers with top-quality
                                    products and unparalleled service.
                                </span>
                            </div>
                        </div>
                        <div className="quality-container px-0 p-md-3 p-lg-3 col-11 col-md-7 col-lg-10 mx-1">
                            <div>
                                <h2 className="aboutUsHeaders title-xl">
                                    Quality Over Quantity
                                </h2>
                                <span className="aboutUs">
                                    We believe that quality and personal service
                                    are more important than quantity. Put
                                    simply: our goods are made to measure. This
                                    requires intense focus on detail to produce
                                    custom-made tyres that are more than just
                                    measurements, rubber thickness, and profile.
                                    We focus on your needs so that the product
                                    you receive is durable and up to your
                                    specifications.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*contact/get in touch/need help links*/}
            <div className="contact-us py-lg-3">
                <p className="contact-us-header title-lg text-bold">Have any questions?</p>
                <div className="contact-us-options">
                    <GenericEmailButton />
                </div>
            </div>

            {/* scroll to top */}
            <ScrollToTop smooth />
            {/* scroll to top */}

            {/* Beginning: footer */}
            <Footer />
            {/* End: footer */}
        </div>
    );
};

export default AboutUsPage;
