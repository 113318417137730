/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ScrollToTop from "react-scroll-to-top";

// Import CSS
import "./Homepage.scss";

// Import components
import Navigation from "../../Components/Navigation/Navigation";
import Footer from "../../Components/Footer/Footer";
import BrandsAutoScroll from "../../Components/BrandsAutoScroll/BrandsAutoScroll";
import Featuredstock from "../../Components/FeaturedProducts/FeaturedStock";
import { useNavigate, useLocation } from "react-router-dom";

// Importing SEO component
import SEO from "../../Components/SEO/SEO";
import Thumbnail from "../../Assests/minestar-thumbnail.webp";

const HomePage = () => {
    //get URL for SEO
    const url = useLocation();
    const path = url.pathname;

    const navigate = useNavigate();

    return (
        <div className="container">
            <SEO 
                title="OTR, Mining & Earthmoving tyre supplier in South Africa | Minestar"
                description="From mining to construction vehicles, we supply all your earthmoving tyre needs. 
                                Shop our OTR tyres for sale now with Minestar South Africa!"
                type="website"
                image={Thumbnail}
                canonUrl={path}
            />
            <div className="item-1">
                <header className="main-header" role="banner">
                    {/* Beginning: The main navigation component */}
                    <Navigation page="HomePage" />
                    {/* End: The main navigation component */}
                </header>
            </div>

            <div
                style={{ flexGrow: 1 }}
                className="d-block d-lg-flex bg-navy py-3 py-xl-4 align-items-center justify-content-center px-5"
            >
                <div className="col-lg-5 pb-4 py-lg-0">
                    <h2 className="contact-banner-text text-center text-lg-left">
                        Are you a supplier looking to sell on our website?
                    </h2>
                </div>
                <div className="col-lg-5 text-center">
                    <button
                        onClick={() => {
                            navigate("/register", { state: true });
                        }}
                        className="contact-banner-button px-4 py-2"
                        type="submit"
                    >
                        Apply Now
                    </button>
                </div>
            </div>

            {/* start of featuredstock */}
            <Featuredstock />
            {/* end of featuredstock */}

            {/* Beginning: Images with auto scroll */}
            <BrandsAutoScroll />
            {/* End: Images with auto scroll */}

            {/* scroll to top */}
            <ScrollToTop smooth />
            {/* scroll to top */}

            {/* Beginning: Application footer */}
            <Footer />
            {/* End: Application footer */}
        </div>
    );
};

export default HomePage;
