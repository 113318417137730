import { useState } from "react";
import { toast } from "react-toastify";
import { API } from "../../../Utility/ApiUrlPath";
import { isObjEmpty } from "../../../Utility/Utils";

const RequestuseForm = (validate, productName, onClose, setSentSuccessfully, setIsLoading) => {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const v = validate(values);
        setErrors(v);
        if (isObjEmpty(v)) {
            const subscriberInfo = {
                ProductName: productName,
                ContactName: values?.name,
                CellNo: values?.phonenumber,
                Email: values?.email,
                Message: values?.message,
            };

            setIsLoading(true);
            fetch(`${API.QA_URL}api/v1/products/post_quote`, {
                method: "POST",
                body: JSON.stringify(subscriberInfo),
                headers: {
                    "Content-Type": "application/json",
                    "minestar-api-token": "xyz",
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (onClose) {
                        onClose();
                    }
                    
                    setIsLoading(false);
                    if (responseJson === 1) {
                        toast.success(
                            "Your request has been sent successfully."
                        );
                        setValues({});
                    } else {
                        toast.error("Your request could not be sent.");
                    }
                })
                .catch((error) => {
                    onClose();
                    setIsLoading(false);
                    // console.error(error);
                    toast.error(
                        "Could not send request. Please try again later."
                    );
                });
        }
    };

    return { handleChange, handleSubmit, values, errors };
};

export default RequestuseForm;
