import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Importing the css files
import "./LoginPage.scss";

// Importing components
import LoginForm from "../Forms/LoginForm/LoginForm";

// import images
import Logo from "../../Assests/Icons/main.png";

// Importing SEO component
import SEO from "../../Components/SEO/SEO";
import Thumbnail from "../../Assests/minestar-thumbnail.webp";

const LoginPage = () => {
    //get URL for SEO
    const url = useLocation();
    const path = url.pathname;

    const navigate = useNavigate();
    return (
        <div className="container">
            <SEO 
                title="Login | Minestar South Africa"
                description="From mining to construction vehicles, we supply all your earthmoving tyre needs. 
                                Shop our OTR tyres for sale now with Minestar South Africa!"
                type="website"
                image={Thumbnail}
                canonUrl={path}
            />
            <div className="loginContainer">
                <div
                    className="login-logo-container col-12"
                    onClick={() => navigate("/")}
                >
                    <img
                        src={Logo}
                        width={500}
                        alt="Minestar South Africa"
                        className=""
                        id="login-logo"
                    />
                </div>
                <div className="col-12 col-lg-8 LoginCover"></div>
                <div className="col-12 col-lg-4 loginForm my-5 my-lg-0">
                    <LoginForm />
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
