import React, { useState } from "react";
import validate from "./ValidateRequestInfo";
import useForm from "./RequestuseForm";
import "./RequestForm.scss";
import MinestarLoader from "../../../Assests/Animations/Minestar-Loader-Small.gif";

const RequestForm = ({ productName, onClose }) => {
    const [sentSuccessfully, setSentSuccessfully] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { handleChange, handleSubmit, values, errors } = useForm(
        validate,
        productName,
        onClose,
        setSentSuccessfully,
        setIsLoading
    );

    return (
        <>
            {isLoading ? (
                <div className="notFoundContent my-1">
                    <div className="d-flex flex-column align-items-center">
                        <img
                            className="my-2 minestar-loader"
                            src={MinestarLoader}
                            alt="Loading..."
                            height={200}
                            width={200}
                        />
                        <p
                            className="not-found-text"
                            style={{ color: "#e50119" }}
                        >
                            Sending Email...
                        </p>
                    </div>
                </div>
            ) : (
                <>
                <h2 className="mt-4">Request More Info or Quotation</h2>
                    <div className="mt-2 mb-3 pl-1 pr-1">
                        <form className="row mx-0" onSubmit={handleSubmit} noValidate>
                            <div className="col-12 col-md-6 row px-0 mx-0">
                                <div className="col-12 col-md-10 px-0">
                                    
                                    <label className="heading-small mb-1 ml-1">
                                        Name
                                    </label>
                                    <input
                                        className="form-control"
                                        id="Reviewername"
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        value={values.name || ""}
                                        onChange={handleChange}
                                    />
                                    {errors.name && (
                                        <p className="text-errors ml-1">
                                            {errors.name}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 col-md-10 px-0 mt-2">
                                    <label className="heading-small mb-1 ml-1">
                                        Phone
                                    </label>
                                    <input
                                        className="form-control"
                                        id="phoneNumber"
                                        type="number"
                                        name="phonenumber"
                                        placeholder="Phone"
                                        value={values.phonenumber || ""}
                                        onChange={handleChange}
                                    />
                                    {errors.phonenumber && (
                                        <p className="text-errors ml-1">
                                            {errors.phonenumber}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 col-md-10 px-0 mt-2">
                                    <label className="heading-small mb-1 ml-1">
                                        Email
                                    </label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={values.email || ""}
                                        onChange={handleChange}
                                    />
                                    {errors.email && (
                                        <p className="text-errors ml-1">
                                            {errors.email}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-6 px-0 justify-content-md-center mt-2 mt-md-0">
                                <label className="heading-small mb-1 ml-1">
                                    Message
                                </label>
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="message"
                                    placeholder="Write your message here..."
                                    value={values.message || ""}
                                    onChange={handleChange}
                                />
                                {errors.message && (
                                    <p className="text-errors ml-1">{errors.message}</p>
                                )}
                            </div>
                            <button className="form-input-btn mt-3" type="submit">
                                Send
                            </button>
                        </form>
                    </div>
                </>
            )}
        </>
    );
};

export default RequestForm;
