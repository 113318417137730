import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import HomePage from "./Pages/HomePage/HomePage";
import ResellerPage from "./Pages/ResellerPage/ResellerPage";
import ProductsPage from "./Pages/ProductsPage/ProductsPage";
import ContactUsPage from "./Pages/ContactUsPage/ContactUsPage";
import BiddingPage from "./Pages/BiddingPage/BiddingPage";
import AboutUsPage from "./Pages/AboutUsPage/AboutUsPage";
import Cart from "./Pages/CartPage/Cart";
import LoginPage from "./Components/Login/LoginPage";
import RegisterPage from "./Components/Register/RegisterPage";
import AccountSettings from "./Components/AccountSettings/AccountSettings";
import ForgotPassword from "./Components/Forms/ForgotPassword/ForgotPassword";
import ResetPassword from "./Components/Forms/ResetPassword/ResetPassword";
import PageNotFound from "./Components/Errors/PageNotFound";
import OzowRedirectComponent from "./Components/PaymentGateways/OzowRedirectComponent";
import ProfileForm from "./Components/Forms/ProfileForm/ProfileForm";
import RequireAuth from "./Components/Security/RequireAuth";
import PaymentFinalLayout from "./Components/PaymentGateways/PaymentFinalLayout";
import AddressList from "./Components/Address/AddressList/AddressList";
import PasswordForm from "./Components/Forms/PasswordForm/PasswordForm";
import "./App.scss";
import OrdersList from "./Components/AccountSettings/OrdersView/OrdersList/OrdersList";
import Mystock from "./Components/Dashboard/MyStock";
import Addstock from "./Components/Dashboard/AddStock";
import SoldStock from "./Components/SoldStock/SoldStock";
import SlimProducts from "./Pages/ProductsPage/SlimProducts";
import SlimProductDetails from "./Components/ProductDetails/SlimProductDetails";
import Construction from "./Construction";
import Chatbot from "./Components/Chatbot/Chatbot";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-PB83J4LN'
}

TagManager.initialize(tagManagerArgs)

function App() {
    return (
        <HelmetProvider>
        <div>
            <Chatbot />
            <Routes caseSensitive>
                {/* !IMPORTANT: This route should only be uncommented when the site goes under maintenance. */}
                {/* <Route path="*" element={<Construction />} /> */}

                <Route path="old" element={<ProductsPage />} />

                <Route path="reseller" element={<ResellerPage />} />

                <Route path="products" element={<SlimProducts />} />

                <Route path="product/:brand/:name/:tyre/:id" element={<SlimProductDetails />} />

                <Route path="about" element={<AboutUsPage />} />

                <Route path="contact" element={<ContactUsPage />} />

                <Route path="bidding" element={<BiddingPage />} />

                <Route path="cart" element={<Cart />} />

                <Route path="login" element={<LoginPage />} />

                <Route path="register" element={<RegisterPage />} />

                <Route path="payment/ozow" element={<PaymentFinalLayout />}>
                    <Route index element={<PageNotFound prop10="test" />} />
                    <Route
                        path="success"
                        element={<OzowRedirectComponent status="success" />}
                    />

                    <Route
                        path="cancel"
                        element={<OzowRedirectComponent status="cancel" />}
                    />

                    <Route
                        path="error"
                        element={<OzowRedirectComponent status="error" />}
                    />

                    <Route path="*" element={<PageNotFound />} />
                </Route>

                <Route path="forgotpassword" element={<ForgotPassword />} />

                <Route path="resetpassword" element={<ResetPassword />} />

                <Route
                    path="accountsettings"
                    element={
                        <RequireAuth redirectTo="/login">
                            <AccountSettings currentState={"orders"} />
                        </RequireAuth>
                    }
                >
                    <Route
                        index
                        element={
                            <RequireAuth redirectTo="/login">
                                <Navigate to={"orders"} />
                            </RequireAuth>
                        }
                    />
                    <Route path="orders" element={<OrdersList />} />
                    <Route path="mystock" element={<Mystock />} />
                    <Route path="soldstock" element={<SoldStock />} />
                    <Route path="addstock" element={<Addstock />} />
                    <Route path="profile" element={<ProfileForm />} />
                    <Route path="password" element={<PasswordForm />} />
                    <Route path="address" element={<AddressList />} />
                    <Route path="*" element={<PageNotFound />} />
                </Route>

                <Route path="/home" element={<HomePage />} />

                <Route exact path="/" element={<HomePage />} />

                <Route path="*" element={<PageNotFound />} />
            </Routes>
            
        </div>
        </HelmetProvider>
    );
}
export default App;
